<!--
 * @Description: 测试 test
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-04 13:37:05
 * @LastEditors: zhoucheng
-->
<template>
  <div class='main'>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    return {
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.main {
  width: 100%;
  height: 100%;
  user-select: none;
  .main-content {
    width: 100%;
    height: calc(100vh - 46px);
    overflow: auto;
    .main-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        margin-top: 10px;
        padding-bottom: 20px;
        border-radius: 5px;
        background: #ffffff;
        .title {
          width: 95%;
          margin-top: 13px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
        }
        .carBG {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          width: 95%;
          height: 245px;
          background: #e7eaee;
          border-radius: 5px;
          overflow: hidden;
          .van-image {
            width: 150px;
            height: 100px;
            margin: 80px 0;
          }
        }
        /deep/ .van-cell {
          width: 95%;
          margin-top: 10px;
          border-radius: 5px;
          background-color: #e7eaee;
        }
        .platInput-box {
          width: 95%;
          margin-top: 10px;
        }
        .platInput-title {
          position: fixed;
          bottom: 315px;
          width: 95%;
          padding: 10px;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #333333;
          background: #ffffff;
          border-bottom: 1px solid rgb(236, 236, 236);
          z-index: 100;
        }
        .plateColor {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          padding-left: 10px;
          box-sizing: border-box;
          line-height: 40px;
          color: #333333;
          font-size: 15px;
          background: #f5f8fc;
          border-radius: 5px;
        }
        .parkNum {
          width: 95%;
          height: 40px;
          margin-top: 10px;
          background: #f5f8fc;
          border-radius: 5px;
        }
        .selectorBerthage {
          width: 95%;
          margin-top: 10px;
          border-radius: 5px;
          box-shadow: 0px 0px 5px 1px rgb(231, 231, 231);
          overflow: hidden;
        }
      }
    }
  }

  .but-box {
    position: fixed;
    bottom: 0;
    justify-content: center;
    align-items: center;
    width: 93.6%;
    height: 50px;
    margin-left: 3.2%;
    margin-top: 10px;
    .van-button {
      width: 100%;
      color: #ffffff;
      font-size: 17px;
      border-radius: 5px;
      background: #19a9fc;
    }
  }
}
.pop-placeholder1 {
  height: 200px;
}
.pop-placeholder2 {
  height: 0px;
}
/deep/.van-uploader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview {
  margin: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/deep/.van-uploader__preview-delete {
  width: 20px;
  height: 20px;
}
/deep/.van-uploader__preview-delete-icon {
  top: 0;
  right: 0;
}
</style>
